import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import Fab from '@material-ui/core/Fab';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HotelIcon from '@material-ui/icons/Hotel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import TrainIcon from '@material-ui/icons/Train';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Layout from '../../../../../components/layout'
import SEO from '../../../../../components/seo'
import Contents from '../../../../../components/contents'
import Paragraphs from '../../../../../components/paragraph'
import Heading from '../../../../../components/heading'
import HeroBox from '../../../../../components/herobox'

import crest from '../../../../../assets/crest/takeda/white/48.png'

const useStyles = makeStyles(theme => ({
    root: {

    },
    root2: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    media: {
    height: 0,
    paddingTop: '56.25%',
    // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#48bd96",
    '& > img': {
        marginBottom: '0',
    },
  },
   crest: {

    },
  clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    clipAvatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        }
    },
    contentRoot: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    imageContainer: {
        margin: '30px 15px',
    },
    gridContainer: {
        padding: '0px',
        margin: '30px 15px',
    },
    heroImageSection: {
        padding: '0px',
        margin: '0px',
    }
}));

const IsawaonsenStationTakeda24GeneralsPage = ({ data }) => {
    const classes = useStyles();
    const language = 'zh_hans';
    const slug = 'station/isawaonsen/takeda-24-generals';
    const pageTitle = '武田二十四将旅馆';
    const takedaShingen1 = '武田信玄';
    const takedaShingen2 = '武田信玄是一名什么样的武将呢？';
    const takedaShingen3 = '武田信玄的魅力在现代也适用';
    const pageSubtitle = '武田二十四將';
    const notChild = false;
    const child = true;

    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            <section className={classes.heroImageSection}>
                <Img objectFit="contain" fluid={data.hero.childImageSharp.fluid} />
            </section>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title={pageTitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>这项纪念活动只到2022年！</p>
                            <p>武田信玄生辰五百周年纪念。</p>
                            <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.flags.childImageSharp.fluid} /></div>
                            <p>以信玄手下的二十四名武将为主题。</p>
                            <p>由石和温泉当地的旅馆或饭店共襄盛举，各自担任一名武将。</p>
                            <p>彷佛回到日本战国时代的气氛，您也来体验看看！</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen1} isChild={notChild} />
                        <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.shingen.childImageSharp.fluid} /></div>
                    </section>
                    <section>
                        <Heading title={takedaShingen2} isChild={child} />
                        <div className={classes.contentRoot}>
                            <p>有战国时代最强武将之称</p>
                            <p>生涯战绩72战49胜3败20和</p>
                            <p>胜率居冠</p>
                            <p>战胜德川家康</p>
                            <p>曾率两千士兵击溃五万大军</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen3} isChild={child} />
                        <div className={classes.contentRoot}>
                            <dl>
                                <dt>运筹帷幄</dt>
                                <dd>不刚愎自用，与家臣合力解决问题。</dd>
                                <dt>情搜能力</dt>
                                <dd>派间谍到全国各地搜集情报以策划战略。</dd>
                                <dt>知人善任</dt>
                                <dd>只要有能有才，即使农民也照样录用为家臣。</dd>
                                <dt>人尽其才</dt>
                                <dd>军事会议上与会者均可发言。</dd>
                            </dl>
                        </div>
                    </section>
                    <section>
                        <Heading title={pageSubtitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>武田信玄的二十四名武将是他的智囊团。</p>
                            <p>战国最强家臣，名不虚传、千古留芳。</p>
                        </div>
                        <div className={classes.gridContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="高坂昌信"
                                            subheader="Kosaka Masanobu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.kousaka.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="高坂昌信"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>高坂昌信是武田军的第一美男子，他与信玄之间的情愫也非常有名。</p>
                                                <p>高坂昌信可以在前线冲锋陷阵，也可以在后方冷静分析战局。由于擅长在敌军有利时撤退，所以他有个绰号叫做“溜之大吉的弹正”。</p>
                                                <p>武田信玄死后，高坂昌信继续留在武田家，在二十四将当中为号称“四天王”之一的名将。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/kikori/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="KIKORI旅馆"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="小幡山城守虎盛"
                                            subheader="Obata Toramori"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.obata.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="小幡山城守虎盛"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>小幡虎盛别称「鬼虎」，是一名善用长枪的勇将。</p>
                                                <p>由于战功彪炳，奉命服侍武田信虎、信玄两代。</p>
                                                <p>在最前线领军是小幡虎盛的作风，兵将士气在他的鼓舞之下总是特别高昂。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/mine/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="和风旅馆 峯"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="甘利备前守虎泰"
                                            subheader="Amari Torayasu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.amari.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="甘利备前守虎泰"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>在二十四将当中，甘利虎泰与板垣信方并称双雄，</p>
                                                <p>武田信虎、信玄两代的重要战役，两人几乎都参加了。</p>
                                                <p>根据文献表示，每当甘利领军的部队出击，还没开打之前敌人就逃之夭夭。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/ukai/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="HOTEL UKAI"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="土屋右卫门尉昌续"
                                            subheader="Tsuchiya Masatsugu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.tsuchiya.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="土屋右卫门尉昌续"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>土屋昌续是武田信玄身边极受信赖的文胆，在激烈的战斗中也坚守着司令部的武田信玄。</p>
                                                <p>17岁第一次披挂上阵的土屋昌续，五年后便晋升为大将，</p>
                                                <p>是一个文武双全实实在在的男人。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/hatta/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="八田饭店"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="马场美浓守信春"
                                            subheader="Baba Nobuharu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.baba.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="马场美浓守信春"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>战历四十年，在70场战役中未曾负伤，</p>
                                                <p>有不死之身的美誉。</p>
                                                <p>生涯尽忠职守，侍奉了武田信虎、信玄、胜赖三代。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/kanpoisawa/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="简保温泉饭店"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="今福净闲斋"
                                            subheader="Imafuku Kansai"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.imafuku.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="今福净闲斋"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>号称战国时代最强的武田家谱代家老，</p>
                                                <p>文武双全，尤其擅文，事务方面表现杰出。</p>
                                                <p>由他负责守护的久能山城，以坚不可摧的防御而被称为「难攻不落之山城」。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/zh_hans/hotel/yamanashi/heian/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="平安旅馆"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </Box>
            </Container>
        </Layout>
        // <pre>{JSON.stringify(data, null, 4)}</pre>
    )
}

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flags:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/flags.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    panel:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/panel.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shingen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/takeda-shingen.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kousaka:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/kousaka-masanobu2.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amari:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/amari.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    baba:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/baba.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imafuku:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/imafuku.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    obata:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/obata.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tsuchiya:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/tsuchiya.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IsawaonsenStationTakeda24GeneralsPage